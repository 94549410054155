import React from 'react';
import { NavPages } from '../components/NavPages';

const Privacy = () => {
  return (
    <>
      <NavPages />
      <section>
        <div class='s-60'></div>
        <div class='m-40'></div>
        <div class='m-20'></div>
        <div class='m-40'></div>
        <div class='m-20'></div>
        <div class='m-40'></div>
        <div class='container'>
          <div class='mx-5 mob-table'>
            <table class='table-mob'>
              <tbody>
                <tr>
                  <td>
                    <strong>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</strong>
                  </td>
                  <td>
                    <strong>PRIVACY POLICY</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Останнє оновлення: 19 вересня 2023 р.</strong>
                  </td>
                  <td>
                    <strong>Last updated September 19, 2023</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Це повідомлення про конфіденційність для ГРОМАДСЬКОЇ
                    ОРГАНІЗАЦІЇ «ГЕЙ БОСС» <strong> </strong>(
                    <a href='https://heyboss.com.ua'>https://heyboss.com.ua</a>)
                    («<strong>Компанія</strong>», «<strong>ми</strong>», «
                    <strong>нас</strong>» або «<strong>наш</strong>») описує, як
                    і чому ми можемо збирати , зберігати, використовувати та/або
                    надавати ("<strong>обробляти</strong>") вашу інформацію,
                    коли ви користуєтеся нашими службами ("
                    <strong>Сервіси</strong>"), наприклад, коли ви:
                  </td>
                  <td>
                    This privacy policy for Non governmental organization «Hey
                    Boss» (
                    <a href='https://heyboss.com.ua'>https://heyboss.com.ua</a>)
                    ("<strong>Company</strong>," "<strong>we</strong>," "
                    <strong>us</strong>," or "<strong>our</strong>"), describes
                    how and why we might collect, store, use, and/or share ("
                    <strong>process</strong>") your information when you use our
                    services ("<strong>Services</strong>"), such as when you:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        відвідуєте наш веб-сайт за адресою{' '}
                        <a href='https://heyboss.com.ua'>
                          https://heyboss.com.ua
                        </a>{' '}
                        або будь-який наш веб-сайт, який посилається на це
                        повідомлення про конфіденційність
                      </li>
                      <li>
                        Взаємодіяти з нами іншими пов’язаними способами, включно
                        з будь-якими продажами, маркетингом або подіями
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Visit our website at{' '}
                        <a href='https://heyboss.com.ua'>
                          https://heyboss.com.ua
                        </a>
                        , or any website of ours that links to this privacy
                        notice
                      </li>
                      <li>
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Маєте запитання чи сумніви? </strong>Ознайомлення з
                    цим повідомленням про конфіденційність допоможе вам
                    зрозуміти ваші права та вибір щодо конфіденційності. Якщо ви
                    не згодні з нашою політикою, будь ласка, не користуйтеся
                    нашими Послугами. Якщо у вас все ще є запитання чи сумніви,
                    зв’яжіться з нами за адресою{' '}
                    <a href='mailto:admin@heyboss.com.ua'>
                      admin@heyboss.com.ua
                    </a>
                  </td>
                  <td>
                    <strong>Questions or concerns? </strong>Reading this privacy
                    notice will help you understand your privacy rights and
                    choices. If you do not agree with our policies and
                    practices, please do not use our Services. If you still have
                    any questions or concerns, please contact us at you may
                    email us at admin@heyboss.com.ua
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>РЕЗЮМЕ КЛЮЧОВИХ ПУНКТІВ</strong>
                  </td>
                  <td>
                    <strong>SUMMARY OF KEY POINTS</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        У цьому короткому викладі наведено ключові моменти з
                        нашої політики конфіденційності.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        This summary provides key points from our privacy
                        policy.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Яку особисту інформацію ми обробляємо?</strong> Коли
                    ви відвідуєте, використовуєте або переміщуєтеся в наших
                    Послугах, ми можемо обробляти особисту інформацію залежно
                    від того, як ви взаємодієте з нашими Послугами, вибору, який
                    ви робите, а також продуктів і функцій, які ви
                    використовуєте.
                  </td>
                  <td>
                    <strong>What personal information do we process?</strong>{' '}
                    When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with our Services, the choices you make, and the products
                    and features you use.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Чи обробляємо ми конфіденційну особисту інформацію?
                    </strong>{' '}
                    Ми не обробляємо конфіденційну особисту інформацію.
                  </td>
                  <td>
                    <strong>
                      Do we process any sensitive personal information?
                    </strong>{' '}
                    We do not process sensitive personal information.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Чи отримуємо ми будь-яку інформацію від третіх сторін?
                    </strong>{' '}
                    Ми не отримуємо жодної інформації від третіх осіб.
                  </td>
                  <td>
                    <strong>
                      Do we receive any information from third parties?
                    </strong>{' '}
                    We do not receive any information from third parties.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Як ми обробляємо вашу інформацію?</strong> Ми
                    обробляємо вашу інформацію, щоб надавати, покращувати та
                    адмініструвати наші Послуги, спілкуватися з вами, для
                    безпеки та запобігання шахрайству, а також для дотримання
                    законодавства. Ми також можемо обробляти вашу інформацію для
                    інших цілей за вашою згодою. Ми обробляємо вашу інформацію
                    лише тоді, коли у нас є вагома законна підстава для цього.
                  </td>
                  <td>
                    <strong>How do we process your information?</strong> We
                    process your information to provide, improve, and administer
                    our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process
                    your information only when we have a valid legal reason to
                    do so.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      У яких ситуаціях і яким сторонам ми надаємо особисту
                      інформацію?
                    </strong>{' '}
                    Ми можемо ділитися інформацією в певних ситуаціях і з
                    певними категоріями третіх сторін.
                  </td>
                  <td>
                    <strong>
                      In what situations and with which types of parties do we
                      share personal information?
                    </strong>{' '}
                    We may share information in specific situations and with
                    specific categories of third parties.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Як ми забезпечуємо безпеку вашої інформації?
                    </strong>{' '}
                    У нас є організаційні та технічні процеси та процедури для
                    захисту вашої особистої інформації. Однак жодна електронна
                    передача через Інтернет або технологія зберігання інформації
                    не може бути гарантовано на 100% безпечною, тому ми не
                    можемо обіцяти або гарантувати, що хакери, кіберзлочинці чи
                    інші неавторизовані треті сторони не зможуть порушити нашу
                    безпеку та неналежним чином збирати, отримувати доступ,
                    викрасти чи змінити вашу інформацію.
                  </td>
                  <td>
                    <strong>How do we keep your information safe?</strong> We
                    have organizational and technical processes and procedures
                    in place to protect your personal information. However, no
                    electronic transmission over the internet or information
                    storage technology can be guaranteed to be 100% secure, so
                    we cannot promise or guarantee that hackers, cybercriminals,
                    or other unauthorized third parties will not be able to
                    defeat our security and improperly collect, access, steal,
                    or modify your information.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Які ваші права?</strong> Залежно від того, де ви
                    географічно розташовані, відповідний закон про
                    конфіденційність може означати, що ви маєте певні права щодо
                    своєї особистої інформації.
                  </td>
                  <td>
                    <strong>What are your rights?</strong> Depending on where
                    you are located geographically, the applicable privacy law
                    may mean you have certain rights regarding your personal
                    information.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Як ви реалізуєте свої права?</strong> Найпростіший
                    спосіб скористатися своїми правами – зв’язатися з нами
                    електронною поштою. Ми розглянемо будь-який запит і
                    відповімо на нього відповідно до чинного законодавства про
                    захист даних.
                  </td>
                  <td>
                    <strong>How do you exercise your rights?</strong> The
                    easiest way to exercise your rights is by contacting us via
                    email. We will consider and act upon any request in
                    accordance with applicable data protection laws.
                  </td>
                </tr>
                <tr>
                  <td>
                    Хочете дізнатися більше про те, що ми робимо з інформацією,
                    яку збираємо? Перегляньте інформацію нижче.
                  </td>
                  <td>
                    Want to learn more about what we do with any information we
                    collect? Check out the information below.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>ЗМІСТ</strong>
                  </td>
                  <td>
                    <strong>TABLE OF CONTENTS</strong>
                  </td>
                </tr>
                <tr>
                  <td>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</td>
                  <td>1. WHAT INFORMATION DO WE COLLECT?</td>
                </tr>
                <tr>
                  <td>2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</td>
                  <td>2. HOW DO WE PROCESS YOUR INFORMATION?</td>
                </tr>
                <tr>
                  <td>
                    3. ЯКИМИ ПРАВОВИМИ ПІДСТАВАМИ МИ КЕРУЄМОСЯ ДЛЯ ОБРОБКИ ВАШОЇ
                    ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ?
                  </td>
                  <td>
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?
                  </td>
                </tr>
                <tr>
                  <td>
                    4. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?
                  </td>
                  <td>
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </td>
                </tr>
                <tr>
                  <td>
                    5. ЧИ ВИКОРИСТОВУЄМО МИ ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ
                    ВІДСТЕЖЕННЯ?
                  </td>
                  <td>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</td>
                </tr>
                <tr>
                  <td>6. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</td>
                  <td>6. HOW LONG DO WE KEEP YOUR INFORMATION?</td>
                </tr>
                <tr>
                  <td>7. ЯК МИ ЗАБЕЗПЕЧУЄМО БЕЗПЕКУ ВАШОЇ ІНФОРМАЦІЇ?</td>
                  <td>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</td>
                </tr>
                <tr>
                  <td>8. ЧИ МИ ЗБИРАЄМО ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</td>
                  <td>8. DO WE COLLECT INFORMATION FROM MINORS?</td>
                </tr>
                <tr>
                  <td>9. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</td>
                  <td>9. WHAT ARE YOUR PRIVACY RIGHTS?</td>
                </tr>
                <tr>
                  <td>10. ЕЛЕМЕНТИ КЕРУВАННЯ ФУНКЦІЯМИ НЕ ВІДСТЕЖУВАТИ</td>
                  <td>10. CONTROLS FOR DO-NOT-TRACK FEATURES</td>
                </tr>
                <tr>
                  <td>
                    11. ЧИ МАЮТЬ ЖИТЕЛІ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА
                    КОНФІДЕНЦІЙНІСТЬ?
                  </td>
                  <td>
                    11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </td>
                </tr>
                <tr>
                  <td>
                    12. ЧИ МИ РОБИМО ОНОВЛЕННЯ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?
                  </td>
                  <td>12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?</td>
                </tr>
                <tr>
                  <td>
                    13. ЯК ВИ МОЖЕТЕ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ
                    КОНФІДЕНЦІЙНОСТІ?
                  </td>
                  <td>13. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?</td>
                </tr>
                <tr>
                  <td>
                    14. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЯКІ
                    МИ ЗБИРАЄМО ВІД ВАС?
                  </td>
                  <td>
                    14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</strong>
                  </td>
                  <td>
                    <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Особиста інформація, яку ви нам розкриваєте</strong>
                  </td>
                  <td>
                    <strong>Personal information you disclose to us</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми збираємо особисту інформацію, яку ви нам
                        надаєте.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We collect personal information that you
                        provide to us.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми збираємо особисту інформацію, яку ви добровільно надаєте
                    нам, коли реєструєтеся на нашому сайті, виражаєте
                    зацікавленість в отриманні інформації про нас або наші
                    продукти та Послуги, коли ви заповнюєте форму на нашому
                    сайті або іншим чином, коли звертаєтеся до нас.
                  </td>
                  <td>
                    We collect personal information that you voluntarily provide
                    to us when you register on our site, express interest in
                    receiving information about us or our products and Services,
                    when you fill out a form on our site, or otherwise contact
                    us.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Особиста інформація, надана вами.</strong> Особиста
                    інформація, яку ми збираємо, залежить від контексту вашої
                    взаємодії з нами та Сервісами, вибору, який ви робите, а
                    також продуктів і функцій, які ви використовуєте. Особиста
                    інформація, яку ми збираємо, може включати наступне:
                  </td>
                  <td>
                    <strong>Personal Information Provided by You.</strong> The
                    personal information that we collect depends on the context
                    of your interactions with us and the Services, the choices
                    you make, and the products and features you use. The
                    personal information we collect may include the following:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>ПІБ,</li>
                      <li>номери телефонів</li>
                      <li>адреса електронної пошти,</li>
                      <li>поштова адреса</li>
                      <li>РНОКПП</li>
                      <li>посилання на сторінку Linkedin</li>
                      <li>фотографія з вашим зображенням</li>
                      <li>відео з вашим зображенням</li>
                      <li>CV</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>names</li>
                      <li>phone numbers</li>
                      <li>email addresses</li>
                      <li>mailing addresses</li>
                      <li>identification code</li>
                      <li>link to the Linkedin page</li>
                      <li>a photo with your image</li>
                      <li>a video with your image</li>
                      <li>CV</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Конфіденційна інформація.</strong> Ми не обробляємо
                    конфіденційну інформацію.
                  </td>
                  <td>
                    <strong>Sensitive Information.</strong> We do not process
                    sensitive information.
                  </td>
                </tr>
                <tr>
                  <td>
                    Уся особиста інформація, яку ви надаєте нам, має бути
                    правдивою, повною та точною, і ви повинні повідомляти нас
                    про будь-які зміни такої особистої інформації.
                  </td>
                  <td>
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Інформація, що збирається автоматично</strong>
                  </td>
                  <td>
                    <strong>Information automatically collected</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: деяка інформація, наприклад ваша адреса
                        Інтернет-протоколу (IP) і/або характеристики браузера та
                        пристрою, збирається автоматично, коли ви відвідуєте
                        наші Сервіси.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: Some information — such as your Internet
                        Protocol (IP) address and/or browser and device
                        characteristics — is collected automatically when you
                        visit our Services.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми автоматично збираємо певну інформацію, коли ви
                    відвідуєте, використовуєте або переміщуєтеся Сервісами. Ця
                    інформація не розкриває вашу особистість (наприклад, ваше
                    ім’я чи контактну інформацію), але може включати інформацію
                    про пристрій і використання, як-от вашу IP-адресу,
                    характеристики веб-переглядача та пристрою, операційну
                    систему, мовні налаштування, URL-адреси переходу, назву
                    пристрою, країну, місцезнаходження, інформацію про те, як і
                    коли ви використовуєте наші Послуги, а також іншу технічну
                    інформацію. Ця інформація в першу чергу потрібна для
                    забезпечення безпеки та роботи наших Сервісів, а також для
                    внутрішньої аналітики та звітності.
                  </td>
                  <td>
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </td>
                </tr>
                <tr>
                  <td>
                    Як і багато компаній, ми також збираємо інформацію за
                    допомогою файлів cookie та подібних технологій.
                  </td>
                  <td>
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                  </td>
                </tr>
                <tr>
                  <td>Інформація, яку ми збираємо, включає:</td>
                  <td>The information we collect includes:</td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <em>дані журналу та використання.</em> Дані журналу та
                        використання — це інформація, пов’язана з послугами,
                        діагностикою, використанням і продуктивністю, яку наші
                        сервери автоматично збирають, коли ви отримуєте доступ
                        до наших Послуг або використовуєте їх, і яку ми
                        записуємо у файли журналів. Залежно від того, як ви
                        взаємодієте з нами, дані журналу можуть включати вашу
                        IP-адресу, інформацію про пристрій, тип браузера, а
                        також, наприклад, позначки дати/часу, пов’язані з вашим
                        використанням, сторінки та файли, які ви переглядаєте,
                        пошуки та інші дії, які ви виконуєте, наприклад, які
                        функції ви використовуєте, інформацію про події пристрою
                        (такі як системна активність, звіти про помилки (іноді
                        їх називають «аварійними дампами») та налаштування
                        апаратного забезпечення).
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <em>Log and Usage Data.</em> Log and usage data is
                        service-related, diagnostic, usage, and performance
                        information our servers automatically collect when you
                        access or use our Services and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type, and settings and information about your
                        activity in the Services (such as the date/time stamps
                        associated with your usage, pages and files viewed,
                        searches, and other actions you take such as which
                        features you use), device event information (such as
                        system activity, error reports (sometimes called "crash
                        dumps"), and hardware settings).
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</strong>
                  </td>
                  <td>
                    <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми обробляємо вашу інформацію, щоб надавати,
                        покращувати та адмініструвати наші Сервіси, спілкуватися
                        з вами, для безпеки та запобігання шахрайству, а також
                        для дотримання законодавства. Ми також можемо обробляти
                        вашу інформацію для інших цілей за вашою згодою.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We process your information to provide,
                        improve, and administer our Services, communicate with
                        you, for security and fraud prevention, and to comply
                        with law. We may also process your information for other
                        purposes with your consent.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Ми обробляємо вашу особисту інформацію з різних причин,
                      залежно від того, як ви взаємодієте з нашими Сервісами,
                      зокрема:
                    </strong>
                  </td>
                  <td>
                    <strong>
                      We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Для укладення угод с вами. </strong>Ми можемо
                        обробляти вашу інформацію для формування відповідних
                        угод із вами та надіслання їх на вашу електронну адресу
                        для підписання.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>To enter into agreements with you.</strong> We
                        may process your information to form relevant agreements
                        with you and send them to your email address for
                        signature.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Для полегшення створення облікового запису та
                          автентифікації та іншого керування обліковими записами
                          користувачів.{' '}
                        </strong>
                        Ми можемо обробляти вашу інформацію, щоб ви могли
                        створити обліковий запис і ввійти в нього, а також
                        підтримувати його в робочому стані.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.{' '}
                        </strong>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Відповідати на запити користувачів/пропонувати
                          підтримку користувачам.{' '}
                        </strong>
                        Ми можемо обробляти вашу інформацію, щоб відповісти на
                        ваші запити та вирішити будь-які потенційні проблеми,
                        які можуть виникнути у вас із запитаною послугою.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          To respond to user inquiries/offer support to users.{' '}
                        </strong>
                        We may process your information to respond to your
                        inquiries and solve any potential issues you might have
                        with the requested service.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Щоб надіслати вам адміністративну інформацію.{' '}
                        </strong>
                        Ми можемо обробляти вашу інформацію, щоб надсилати вам
                        докладну інформацію про наші продукти та послуги, зміни
                        наших умов і політики та іншу подібну інформацію.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          To send administrative information to you.{' '}
                        </strong>
                        We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Щоб запитати відгук. </strong>Ми можемо
                        обробляти вашу інформацію, коли це необхідно, щоб
                        отримати відгук і зв’язатися з вами щодо використання
                        вами наших Послуг.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>To request feedback. </strong>We may process
                        your information when necessary to request feedback and
                        to contact you about your use of our Services.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Щоб надсилати вам маркетингові та рекламні
                          повідомлення.{' '}
                        </strong>
                        Ми можемо обробляти особисту інформацію, яку ви
                        надсилаєте нам, для наших маркетингових цілей, якщо це
                        відповідає вашим маркетинговим уподобанням. Ви можете
                        будь-коли відмовитися від наших маркетингових
                        електронних листів. Для отримання додаткової інформації
                        див. «ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?» нижче).
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          To send you marketing and promotional communications.{' '}
                        </strong>
                        We may process the personal information you send to us
                        for our marketing purposes, if this is in accordance
                        with your marketing preferences. You can opt out of our
                        marketing emails at any time. For more information, see
                        "WHAT ARE YOUR PRIVACY RIGHTS?" below).
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Для доставки вам цільової реклами.</strong> Ми
                        можемо обробляти вашу інформацію для розробки та
                        відображення персоналізованого вмісту та реклами
                        відповідно до ваших інтересів, місцезнаходження тощо.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>To deliver targeted advertising to you.</strong>{' '}
                        We may process your information to develop and display
                        personalized content and advertising tailored to your
                        interests, location, and more.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Щоб захистити наші Сервіси.</strong> Ми можемо
                        обробляти вашу інформацію в рамках наших зусиль, щоб
                        забезпечити безпеку та безпеку наших Сервісів, включаючи
                        моніторинг та запобігання шахрайству.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>To protect our Services.</strong> We may process
                        your information as part of our efforts to keep our
                        Services safe and secure, including fraud monitoring and
                        prevention.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Щоб визначити тенденції використання.</strong>{' '}
                        Ми можемо обробляти інформацію про те, як ви
                        використовуєте наші Сервіси, щоб краще зрозуміти, як
                        вони використовуються, щоб ми могли їх покращити.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>To identify usage trends.</strong> We may
                        process information about how you use our Services to
                        better understand how they are being used so we can
                        improve them.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Щоб визначити ефективність наших маркетингових і
                          рекламних кампаній.
                        </strong>{' '}
                        Ми можемо обробляти вашу інформацію, щоб краще
                        зрозуміти, як проводити маркетингові та рекламні
                        кампанії, які є найбільш релевантними для вас.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          To determine the effectiveness of our marketing and
                          promotional campaigns.
                        </strong>{' '}
                        We may process your information to better understand how
                        to provide marketing and promotional campaigns that are
                        most relevant to you.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Для збереження або захисту життєво важливих інтересів
                          особи.
                        </strong>{' '}
                        Ми можемо обробляти вашу інформацію, коли це необхідно
                        для збереження або захисту життєво важливих інтересів
                        особи, наприклад, щоб запобігти завданню шкоди
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          To save or protect an individual's vital interest.
                        </strong>{' '}
                        We may process your information when necessary to save
                        or protect an individual’s vital interest, such as to
                        prevent harm.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>
                          Для покращення та популяризації наших Сервісів.{' '}
                        </strong>
                        Ми можемо обробляти вашу інформацію шляхом розміщення
                        фотографій та відео із вашим зображенням на наших
                        веб-сайтах (сервісах), та веб- сайтах наших партнерів.
                        При цьому ми можемо покращувати якість наданих вами фото
                        та відео у будь-якій спосіб без спотворення вашого
                        зображення (це може бути кроп, заміна фону, яркості,
                        тощо).
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>To improve and popularize our Services.</strong>{' '}
                        We may process your information by placing photos and
                        videos with your image on our websites (services) and
                        the websites of our partners. At the same time, we can
                        improve the quality of the photos and videos provided by
                        you in any way without distorting your image (it can be
                        cropping, replacing the background, brightness, etc.)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      3. ЯКИМИ ПРАВОВИМИ ПІДСТАВАМИ МИ КЕРУЄМОСЯ ДЛЯ ОБРОБКИ
                      ВАШОЇ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                      INFORMATION?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми обробляємо вашу особисту інформацію лише
                        тоді, коли вважаємо це необхідним і маємо вагомі правові
                        підстави (тобто правові підстави) для цього відповідно
                        до чинного законодавства, наприклад, за вашою згодою,
                        для дотримання законів, для надання вам послуг для
                        прийняття або виконання наших договірних зобов’язань,
                        захисту ваших прав або виконання наших законних ділових
                        інтересів.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We only process your personal information when
                        we believe it is necessary and we have a valid legal
                        reason (i.e., legal basis) to do so under applicable
                        law, like with your consent, to comply with laws, to
                        provide you with services to enter into or fulfill our
                        contractual obligations, to protect your rights, or to
                        fulfill our legitimate business interests.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        <span style={{ textDecoration: 'underline' }}>
                          Якщо ви перебуваєте в ЄС або Великобританії, цей
                          розділ стосується вас.
                        </span>
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        <span style={{ textDecoration: 'underline' }}>
                          If you are located in the EU or UK, this section
                          applies to you.
                        </span>
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Загальний регламент захисту даних (GDPR) і GDPR
                    Великобританії вимагають від нас пояснення чинних правових
                    підстав, на які ми спираємося для обробки вашої особистої
                    інформації. Таким чином, ми можемо покладатися на такі
                    правові основи для обробки вашої особистої інформації:
                  </td>
                  <td>
                    The General Data Protection Regulation (GDPR) and UK GDPR
                    require us to explain the valid legal bases we rely on in
                    order to process your personal information. As such, we may
                    rely on the following legal bases to process your personal
                    information:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Згода. </strong>Ми можемо обробляти вашу
                        інформацію, якщо ви дали нам дозвіл (тобто згоду) на
                        використання вашої особистої інформації з певною метою.
                        Ви можете відкликати свою згоду в будь-який час.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>Consent. </strong>We may process your
                        information if you have given us permission (i.e.,
                        consent) to use your personal information for a specific
                        purpose. You can withdraw your consent at any time.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Виконання контракту.</strong> Ми можемо
                        обробляти вашу особисту інформацію, якщо вважаємо це
                        необхідним для виконання наших договірних зобов’язань
                        перед вами, включаючи надання наших Послуг, або за вашим
                        запитом до укладення з вами контракту.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>Performance of a Contract.</strong> We may
                        process your personal information when we believe it is
                        necessary to fulfill our contractual obligations to you,
                        including providing our Services or at your request
                        prior to entering into a contract with you.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Законні інтереси.</strong> Ми можемо обробляти
                        вашу інформацію, якщо вважаємо, що це обґрунтовано
                        необхідно для досягнення наших законних ділових
                        інтересів, і ці інтереси не переважають ваші інтереси та
                        основні права та свободи. Наприклад, ми можемо обробляти
                        вашу особисту інформацію для деяких із описаних цілей,
                        щоб:
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>Legitimate Interests.</strong> We may process
                        your information when we believe it is reasonably
                        necessary to achieve our legitimate business interests
                        and those interests do not outweigh your interests and
                        fundamental rights and freedoms. For example, we may
                        process your personal information for some of the
                        purposes described in order to:
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        надсилати користувачам інформацію про спеціальні
                        пропозиції та знижки на наші продукти та послуги;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Send users information about special offers and
                        discounts on our products and services
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        розробляти та відображати персоналізований і відповідний
                        рекламний вміст для наших користувачів (в тому числі
                        шляхом розміщення фотографій та відео із вашим
                        зображенням на наших веб-сайтах (сервісах), та
                        веб-сайтах наших партнерів);
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Develop and display personalized and relevant
                        advertising content for our users (including by placing
                        photos and videos with your image on our websites
                        (services), and websites of our partners);
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        аналізувати, як використовуються наші Сервіси, щоб ми
                        могли покращити їх, щоб залучати й утримувати
                        користувачів
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Analyze how our Services are used so we can improve them
                        to engage and retain users
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Підтримувати нашу маркетингову діяльність</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Support our marketing activities</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Діагностувати проблеми та/або запобігати шахрайським
                        діям
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Diagnose problems and/or prevent fraudulent activities
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Зрозуміти, як наші користувачі використовують наші
                        продукти та послуги, щоб ми могли покращити взаємодію з
                        ними.<strong> </strong>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Understand how our users use our products and services
                        so we can improve user experience
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Юридичні зобов’язання.</strong> Ми можемо обробляти
                    вашу інформацію, якщо вважаємо, що це необхідно для
                    дотримання наших юридичних зобов’язань, наприклад, для
                    співпраці з правоохоронними органами або регулюючими
                    (контролюючими) органами, реалізації або захисту наших
                    законних прав або розкриття вашої інформації як доказу в
                    судовому процесі, в який ми залучені.
                  </td>
                  <td>
                    <strong>Legal Obligations.</strong> We may process your
                    information where we believe it is necessary for compliance
                    with our legal obligations, such as to cooperate with a law
                    enforcement body or regulator agency, exercise or defend our
                    legal rights, or disclose your information as evidence in
                    litigation in which we are involved.
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <strong>Життєво важливі інтереси.</strong> Ми можемо
                        обробляти вашу інформацію, якщо вважаємо це необхідним
                        для захисту ваших життєво важливих інтересів або життєво
                        важливих інтересів третьої сторони, наприклад, у
                        ситуаціях, пов’язаних із потенційною загрозою безпеці
                        будь-якої особи.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <strong>Vital Interests.</strong> We may process your
                        information where we believe it is necessary to protect
                        your vital interests or the vital interests of a third
                        party, such as situations involving potential threats to
                        the safety of any person.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    З юридичної точки зору ми зазвичай є «контролером даних»
                    відповідно до європейських законів про захист персональних
                    даних, описаних у цієї політики конфіденційності, оскільки
                    ми визначаємо засоби та/або цілі обробки даних, які ми
                    виконуємо. Ця політика конфіденційності не стосується
                    особистої інформації, яку ми обробляємо як «обробник даних»
                    від імені наших клієнтів. У таких ситуаціях клієнт, якому ми
                    надаємо послуги та з яким ми уклали угоду про обробку даних,
                    є «контролером даних», відповідальним за вашу особисту
                    інформацію, і ми лише обробляємо вашу інформацію від його
                    імені відповідно до ваших інструкцій. Якщо ви хочете
                    дізнатися більше про методи забезпечення конфіденційності
                    наших клієнтів, ви повинні прочитати їхню політику
                    конфіденційності та спрямувати їм будь-які запитання.
                  </td>
                  <td>
                    In legal terms, we are generally the "data controller" under
                    European data protection laws of the personal information
                    described in this privacy notice, since we determine the
                    means and/or purposes of the data processing we perform.
                    This privacy policy does not apply to the personal
                    information we process as a "data processor" on behalf of
                    our customers. In those situations, the customer that we
                    provide services to and with whom we have entered into a
                    data processing agreement is the "data controller"
                    responsible for your personal information, and we merely
                    process your information on their behalf in accordance with
                    your instructions. If you want to know more about our
                    customers' privacy practices, you should read their privacy
                    policies and direct any questions you have to them.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        <span style={{ textDecoration: 'underline' }}>
                          Якщо ви перебуваєте в Канаді, цей розділ стосується
                          вас.
                        </span>
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        <span style={{ textDecoration: 'underline' }}>
                          If you are located in Canada, this section applies to
                          you.
                        </span>
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми можемо обробляти вашу інформацію, якщо ви дали нам
                    окремий дозвіл (тобто явну згоду) на використання вашої
                    особистої інформації для певної мети або в ситуаціях, коли
                    ваш дозвіл можна припустити (тобто непряма згода). Ви можете
                    відкликати свою згоду в будь-який час.
                  </td>
                  <td>
                    We may process your information if you have given us
                    specific permission (i.e., express consent) to use your
                    personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e.,
                    implied consent). You can withdraw your consent at any time.
                  </td>
                </tr>
                <tr>
                  <td>
                    У деяких виняткових випадках відповідно до чинного
                    законодавства нам може бути дозволено обробляти вашу
                    інформацію без вашої згоди, зокрема, наприклад:
                  </td>
                  <td>
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process your information without your
                    consent, including, for example:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо збір явно відповідає інтересам особи, а згоду не
                        можна отримати своєчасно
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If collection is clearly in the interests of an
                        individual and consent cannot be obtained in a timely
                        way
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Для розслідувань виявлення і запобігання шахрайства
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        For investigations and fraud detection and prevention
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Для ділових операцій за умови дотримання певних умов
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        For business transactions provided certain conditions
                        are met
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо це міститься в показаннях свідка, а збір необхідний
                        для оцінки, обробки або врегулювання страхової претензії
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If it is contained in a witness statement and the
                        collection is necessary to assess, process, or settle an
                        insurance claim
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Для ідентифікації поранених, хворих або померлих осіб і
                        спілкування з найближчими родичами
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        For identifying injured, ill, or deceased persons and
                        communicating with next of kin
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо ми маємо обґрунтовані підстави вважати, що особа
                        була, є чи може стати жертвою фінансових зловживань.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If we have reasonable grounds to believe an individual
                        has been, is, or may be victim of financial abuse
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо розумно очікувати, що збір і використання за згодою
                        поставить під загрозу доступність або точність
                        інформації, а збір є розумним для цілей, пов’язаних із
                        розслідуванням порушення угоди або порушення законів
                        Канади чи провінції.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If it is reasonable to expect collection and use with
                        consent would compromise the availability or the
                        accuracy of the information and the collection is
                        reasonable for purposes related to investigating a
                        breach of an agreement or a contravention of the laws of
                        Canada or a province
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо розголошення необхідно для виконання за: повісткою
                        в суд, ордером, ухвалою суду або правилами суду, що
                        стосуються надання записів.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If disclosure is required to comply with a subpoena,
                        warrant, court order, or rules of the court relating to
                        the production of records
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        якщо вони були створені особою під час її роботи,
                        бізнесу чи професійної діяльності, і збір відповідає
                        цілям, для яких інформація була створена
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If it was produced by an individual in the course of
                        their employment, business, or profession and the
                        collection is consistent with the purposes for which the
                        information was produced
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо збір призначений виключно для журналістських,
                        мистецьких або літературних цілей
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If the collection is solely for journalistic, artistic,
                        or literary purposes
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Якщо інформація є загальнодоступною та визначена
                        правилами
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        If the information is publicly available and is
                        specified by the regulations
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      4. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми можемо ділитися інформацією в конкретних
                        ситуаціях, описаних у цьому розділі, та/або з такими
                        категоріями третіх сторін.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We may share information in specific
                        situations described in this section and/or with the
                        following categories of third parties.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Постачальники, консультанти, партнери та інші сторонні
                      постачальники послуг.
                    </strong>{' '}
                    Ми можемо надавати ваші дані стороннім постачальникам,
                    постачальникам послуг, партнерам, підрядникам або агентам («
                    <strong>треті сторони</strong>»), які надають послуги для
                    нас (з якими ми маємо партнерські відносини) або від нашого
                    імені та потребують доступу до такої інформації для
                    виконання такої роботи. У нас є контракти (або будуть
                    укладені в момент передачі даних) з нашими третіми
                    сторонами, які призначені для захисту вашої особистої
                    інформації. Це означає, що вони не можуть нічого робити з
                    вашою особистою інформацією, якщо ми не доручимо їм це
                    зробити. Вони також не повідомлятимуть вашу особисту
                    інформацію жодній організації, крім нас. Вони також
                    зобов’язуються захищати дані, які вони зберігають від нашого
                    імені, і зберігати їх протягом періоду, який ми даємо.
                    Категорії третіх осіб, яким ми можемо надавати особисту
                    інформацію: інструменти маркетингу та продажів.
                  </td>
                  <td>
                    <strong>
                      Vendors, Consultants, and Other Third-Party Service
                      Providers.
                    </strong>{' '}
                    We may share your data with third-party vendors, service
                    providers, partners contractors, or agents ("
                    <strong>third parties</strong>") who perform services for us
                    (to whom we have partnership with) or on our behalf and
                    require access to such information to do that work. We have
                    contracts in place (or will be concluded at the time of data
                    transfer) with our third parties, which are designed to help
                    safeguard your personal information. This means that they
                    cannot do anything with your personal information unless we
                    have instructed them to do it. They will also not share your
                    personal information with any organization apart from us.
                    They also commit to protect the data they hold on our behalf
                    and to retain it for the period we instruct. The categories
                    of third parties we may share personal information with are
                    as follows: Sales &amp; Marketing Tools
                  </td>
                </tr>
                <tr>
                  <td>
                    Нам також може знадобитися надати вашу особисту інформацію в
                    таких ситуаціях: Ми можемо надавати або передавати вашу
                    інформацію у зв’язку з або під час переговорів щодо
                    будь-якого злиття, продажу активів компанії, фінансування
                    або придбання всього або частини нашого бізнесу іншій
                    компанії.
                  </td>
                  <td>
                    We also may need to share your personal information in the
                    following situations: We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      5. ЧИ ВИКОРИСТОВУЄМО МИ ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ
                      ВІДСТЕЖЕННЯ?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми можемо використовувати файли cookie та інші
                        технології відстеження для збору та зберігання вашої
                        інформації.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We may use cookies and other tracking
                        technologies to collect and store your information.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми можемо використовувати файли cookie та подібні технології
                    відстеження (наприклад, веб-маяки та пікселі) для доступу
                    або зберігання інформації.
                  </td>
                  <td>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>6. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</strong>
                  </td>
                  <td>
                    <strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми зберігаємо вашу інформацію стільки, скільки
                        необхідно для досягнення цілей, викладених у цієї
                        політики конфіденційності, якщо інше не вимагається
                        законом.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We keep your information for as long as
                        necessary to fulfill the purposes outlined in this
                        privacy policy unless otherwise required by law.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми зберігатимемо вашу особисту інформацію лише до тих пір,
                    поки це необхідно для цілей, викладених у цієї політики
                    конфіденційності, за винятком випадків, коли довший період
                    зберігання вимагається або дозволяється законодавством
                    (наприклад, вимогами податкового, бухгалтерського обліку чи
                    іншими правовими вимогами). Жодна мета цього повідомлення не
                    вимагає від нас зберігати вашу особисту інформацію довше
                    тридцяти шести (36) місяців після припинення дії облікового
                    запису користувача або розірвання відповідного договору або
                    відзиву цієї згоди.
                  </td>
                  <td>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    policy, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice requires us to
                    retain your personal information for longer than thirty-six
                    (36) months after the user's account is terminated or the
                    applicable agreement is terminated or this consent is
                    revoked.
                  </td>
                </tr>
                <tr>
                  <td>
                    Якщо у нас немає поточної законної комерційної потреби
                    обробляти вашу особисту інформацію, ми або видалимо, або
                    зробимо таку інформацію анонімною, або, якщо це неможливо
                    (наприклад, оскільки ваша особиста інформація зберігалася в
                    резервних архівах), ми безпечно будемо зберігати вашу
                    особисту інформацію та ізолюємо її від будь-якої подальшої
                    обробки, доки не стане можливим видалення.
                  </td>
                  <td>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing until deletion is possible.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      7. ЯК МИ ЗАБЕЗПЕЧУЄМО БЕЗПЕКУ ВАШОЇ ІНФОРМАЦІЇ?
                    </strong>
                  </td>
                  <td>
                    <strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми прагнемо захистити вашу особисту інформацію
                        за допомогою системи організаційних і технічних заходів
                        безпеки.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We aim to protect your personal information
                        through a system of organizational and technical
                        security measures.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми запровадили відповідні та розумні технічні та
                    організаційні заходи безпеки, спрямовані на захист будь-якої
                    особистої інформації, яку ми обробляємо. Однак, незважаючи
                    на наші заходи безпеки та зусилля щодо захисту вашої
                    інформації, жодна електронна передача через Інтернет або
                    технологія зберігання інформації не може бути гарантовано на
                    100% безпечною, тому ми не можемо обіцяти або гарантувати,
                    що хакери, кіберзлочинці чи інші неавторизовані треті
                    сторони не можуть порушити нашу безпеку та неналежним чином
                    збирати, отримувати доступ, викрадати або змінювати вашу
                    інформацію. Незважаючи на те, що ми зробимо все можливе, щоб
                    захистити вашу особисту інформацію, передача особистої
                    інформації до наших Сервісів (а також із них) здійснюється
                    на ваш власний ризик. Ви повинні отримати доступ до Сервісів
                    лише в безпечному середовищі.
                  </td>
                  <td>
                    We have implemented appropriate and reasonable technical and
                    organizational security measures designed to protect the
                    security of any personal information we process. However,
                    despite our safeguards and efforts to secure your
                    information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not
                    be able to defeat our security and improperly collect,
                    access, steal, or modify your information. Although we will
                    do our best to protect your personal information,
                    transmission of personal information to and from our
                    Services is at your own risk. You should only access the
                    Services within a secure environment.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      8. ЧИ МИ ЗБИРАЄМО ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?
                    </strong>
                  </td>
                  <td>
                    <strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: ми свідомо не збираємо дані від дітей віком до
                        18 років і не продаємо їм дані.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: We do not knowingly collect data from or
                        market to children under 18 years of age.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми свідомо не збираємо та не продаємо дані дітям віком до 18
                    років. Користуючись Сервісами, ви підтверджуєте, що вам
                    виповнилося принаймні 18 років, або що ви є батьком або
                    опікуном такої неповнолітньої особи та погоджуєтеся на
                    використання Сервісів неповнолітньою особою. Якщо ми
                    дізнаємося, що була зібрана особиста інформація користувачів
                    віком до 18 років, ми деактивуємо обліковий запис і вживемо
                    розумних заходів для негайного видалення таких даних із
                    наших записів. Якщо вам стало відомо про будь-які дані, які
                    ми могли зібрати від дітей віком до 18 років, зв’яжіться з
                    нами за адресою{' '}
                    <a href='mailto:admin@heyboss.com.ua'>
                      admin@heyboss.com.ua
                    </a>
                  </td>
                  <td>
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Services, you represent
                    that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor
                    dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we may have
                    collected from children under age 18, please contact us at
                    admin@heyboss.com.ua
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>9. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</strong>
                  </td>
                  <td>
                    <strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: у деяких регіонах, наприклад у Європейській
                        економічній зоні (ЄЕЗ), Сполученому Королівстві
                        (Великому Королівстві) і Канаді, ви маєте права, які
                        надають вам більший доступ до вашої особистої інформації
                        та контроль над нею. Ви можете переглянути, змінити або
                        припинити дію свого облікового запису в будь-який час.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: In some regions, such as the European Economic
                        Area (EEA), United Kingdom (UK), and Canada, you have
                        rights that allow you greater access to and control over
                        your personal information. You may review, change, or
                        terminate your account at any time.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    У деяких регіонах (наприклад, Велика Британія, Канада) ви
                    маєте певні права відповідно до чинного законодавства про
                    захист даних. Це може включати право (i) вимагати доступу та
                    отримання копії вашої особистої інформації, (ii) вимагати
                    виправлення або видалення; (iii) забороняти обробку вашої
                    особистої інформації, тощо. За певних обставин ви також
                    можете мати право заперечити проти обробки вашої особистої
                    інформації. Ви можете зробити такий запит, зв’язавшись з
                    нами, використовуючи контактну інформацію, наведену в
                    розділі «ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ
                    КОНФІДЕНЦІЙНОСТІ?» нижче.
                  </td>
                  <td>
                    In some regions (like the EEA, UK, and Canada), you have
                    certain rights under applicable data protection laws. These
                    may include the right (i) to request access and obtain a
                    copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to restrict the processing
                    of your personal information; and (iv) if applicable, to
                    data portability. In certain circumstances, you may also
                    have the right to object to the processing of your personal
                    information. You can make such a request by contacting us by
                    using the contact details provided in the section "HOW CAN
                    YOU CONTACT US ABOUT THIS NOTICE?" below.
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми розглянемо будь-який запит і відповімо на нього
                    відповідно до чинного законодавства про захист даних.
                  </td>
                  <td>
                    We will consider and act upon any request in accordance with
                    applicable data protection laws.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <span style={{ textDecoration: 'underline' }}>
                        Відкликання вашої згоди:
                      </span>
                    </strong>{' '}
                    якщо ми покладаємося на вашу згоду на обробку вашої
                    особистої інформації, яка може бути прямою та/або непрямою
                    згодою залежно від чинного законодавства, ви маєте право
                    відкликати свою згоду в будь-який час. Ви можете відкликати
                    свою згоду в будь-який час, зв’язавшись з нами,
                    використовуючи контактну інформацію, наведену в розділі «ЯК
                    ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ
                    КОНФІДЕНЦІЙНОСТІ?» нижче.
                  </td>
                  <td>
                    <strong>
                      <span style={{ textDecoration: 'underline' }}>
                        Withdrawing your consent:
                      </span>
                    </strong>{' '}
                    If we are relying on your consent to process your personal
                    information, which may be express and/or implied consent
                    depending on the applicable law, you have the right to
                    withdraw your consent at any time. You can withdraw your
                    consent at any time by contacting us by using the contact
                    details provided in the section "HOW CAN YOU CONTACT US
                    ABOUT THIS PRIVACY POLICY?" below.
                  </td>
                </tr>
                <tr>
                  <td>
                    Однак зауважте, що це не вплине на законність обробки до її
                    відкликання, а також, якщо це дозволяє чинне законодавство,
                    це не вплине на обробку вашої особистої інформації, яка
                    виконується на підставі законної обробки, крім згоди.
                  </td>
                  <td>
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal nor, when
                    applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful
                    processing grounds other than consent.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <span style={{ textDecoration: 'underline' }}>
                        Відмова від маркетингових і рекламних повідомлень:{' '}
                      </span>
                    </strong>
                    Ви можете будь-коли скасувати підписку на наші маркетингові
                    та рекламні повідомлення, відповівши «СТОП» або «СКАСУВАТИ
                    ПІДПИСКУ» на SMS-повідомлення, які ми надсилаємо, або
                    зв’язавшись з нами, використовуючи інформацію, наведену в
                    розділі «ЯК ЧИ МОЖЕТЕ ВИ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ
                    ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?" нижче. Після цього вас буде
                    видалено з маркетингових списків. Однак ми все одно можемо
                    спілкуватися з вами, наприклад, щоб надсилати вам пов’язані
                    з послугами повідомлення, необхідні для адміністрування та
                    використання вашого облікового запису, відповідати на запити
                    щодо обслуговування або для інших немаркетингових цілей.
                  </td>
                  <td>
                    <strong>
                      <span style={{ textDecoration: 'underline' }}>
                        Opting out of marketing and promotional communications:{' '}
                      </span>
                    </strong>
                    You can unsubscribe from our marketing and promotional
                    communications at any time by replying "STOP" or
                    "UNSUBSCRIBE" to the SMS messages that we send, or by
                    contacting us using the details provided in the section "HOW
                    CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then
                    be removed from the marketing lists. However, we may still
                    communicate with you — for example, to send you
                    service-related messages that are necessary for the
                    administration and use of your account, to respond to
                    service requests, or for other non-marketing purposes.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Інформація про обліковий запис</strong>
                  </td>
                  <td>
                    <strong>Account Information</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Якщо ви в будь-який момент захочете переглянути чи змінити
                    інформацію у своєму обліковому записі чи припинити дію
                    вашого облікового запису, ви можете:
                  </td>
                  <td>
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Зв’язатися з нами, використовуючи надану контактну
                        інформацію.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Contact us using the contact information provided.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Після вашого запиту на припинення дії вашого облікового
                    запису ми деактивуємо або видалимо ваш обліковий запис і
                    інформацію з наших активних баз даних. Однак ми можемо
                    зберігати певну інформацію в наших файлах, щоб запобігти
                    шахрайству, усунути проблеми, допомогти в будь-яких
                    розслідуваннях, забезпечити дотримання наших юридичних умов
                    і/або дотримання відповідних юридичних вимог.
                  </td>
                  <td>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <span style={{ textDecoration: 'underline' }}>
                        Файли cookie та подібні технології:
                      </span>
                    </strong>{' '}
                    більшість веб-браузерів налаштовано на прийом файлів cookie
                    за замовчуванням. Якщо ви бажаєте, ви зазвичай можете
                    налаштувати свій браузер на видалення та відхилення файлів
                    cookie. Якщо ви вирішите видалити або відхилити файли
                    cookie, це може вплинути на певні функції або служби наших
                    Сервісів.
                  </td>
                  <td>
                    <strong>
                      <span style={{ textDecoration: 'underline' }}>
                        Cookies and similar technologies:
                      </span>
                    </strong>{' '}
                    Most Web browsers are set to accept cookies by default. If
                    you prefer, you can usually choose to set your browser to
                    remove cookies and to reject cookies. If you choose to
                    remove cookies or reject cookies, this could affect certain
                    features or services of our Services.
                  </td>
                </tr>
                <tr>
                  <td>
                    Якщо у вас є запитання чи коментарі щодо ваших прав на
                    конфіденційність, ви можете надіслати нам електронний лист
                    на адресу admin@heyboss.com.ua
                  </td>
                  <td>
                    If you have questions or comments about your privacy rights,
                    you may email us at<p>admin@heyboss.com.ua</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      10. ЕЛЕМЕНТИ КЕРУВАННЯ ФУНКЦІЯМИ НЕ ВІДСТЕЖУВАТИ
                    </strong>
                  </td>
                  <td>
                    <strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Більшість веб-браузерів і деякі мобільні операційні системи
                    та програми для мобільних пристроїв включають функцію
                    заборони відстеження («DNT» Do not Track). На даному етапі
                    не розроблено єдиного технологічного стандарту для
                    розпізнавання та реалізації сигналів DNT. Таким чином,
                    наразі ми не реагуємо на сигнали браузера DNT або будь-який
                    інший механізм, який автоматично повідомляє про ваш вибір не
                    відстежувати вас в Інтернеті. Якщо буде прийнято стандарт
                    онлайн-відстеження, якого ми повинні дотримуватися в
                    майбутньому, ми повідомимо вас про цю практику в
                    переглянутій версії цього повідомлення про конфіденційність.
                  </td>
                  <td>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform
                    technology standard for recognizing and implementing DNT
                    signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      11. ЧИ МАЮТЬ ЖИТЕЛІ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА
                      КОНФІДЕНЦІЙНІСТЬ?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: так, якщо ви є резидентом Каліфорнії, вам
                        надаються певні права щодо доступу до вашої особистої
                        інформації.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: Yes, if you are a resident of California, you
                        are granted specific rights regarding access to your
                        personal information.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Розділ 1798.83 Цивільного кодексу Каліфорнії, також відомий
                    як закон «Shine The Light», дозволяє нашим користувачам, які
                    є жителями Каліфорнії, запитувати та отримувати від нас раз
                    на рік безкоштовно інформацію про категорії особистої
                    інформації (за наявності), яку ми надаємо третім особам для
                    цілей прямого маркетингу, а також імена й адреси всіх третіх
                    сторін, яким ми надали особисту інформацію безпосередньо за
                    попередній календарний рік. Якщо ви проживаєте в Каліфорнії
                    та бажаєте подати такий запит, надішліть нам свій запит у
                    письмовій формі, використовуючи контактну інформацію,
                    наведену нижче.
                  </td>
                  <td>
                    California Civil Code Section 1798.83, also known as the
                    "Shine The Light" law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </td>
                </tr>
                <tr>
                  <td>
                    Якщо вам менше 18 років, ви проживаєте в Каліфорнії та маєте
                    обліковий запис у Сервісах, ви маєте право вимагати
                    видалення небажаних даних, які ви публічно опублікуєте в
                    Сервісах. Щоб подати запит на видалення таких даних,
                    зв’яжіться з нами, використовуючи контактну інформацію,
                    наведену нижче, і вкажіть адресу електронної пошти,
                    пов’язану з вашим обліковим записом, і заяву про те, що ви
                    проживаєте в Каліфорнії. Ми подбаємо про те, щоб дані не
                    були публічно відображені в Сервісах, але майте на увазі, що
                    дані не можуть бути повністю видалені з усіх наших систем
                    (наприклад, резервні копії тощо).
                  </td>
                  <td>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with Services, you have the right
                    to request removal of unwanted data that you publicly post
                    on the Services. To request removal of such data, please
                    contact us using the contact information provided below and
                    include the email address associated with your account and a
                    statement that you reside in California. We will make sure
                    the data is not publicly displayed on the Services, but
                    please be aware that the data may not be completely or
                    comprehensively removed from all our systems (e.g., backups,
                    etc.).
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Повідомлення про конфіденційність CCPA</strong>
                  </td>
                  <td>
                    <strong>CCPA Privacy Notice</strong>
                  </td>
                </tr>
                <tr>
                  <td>Кодекс правил Каліфорнії визначає «резидента» як:</td>
                  <td>
                    The California Code of Regulations defines a "resident" as:
                  </td>
                </tr>
                <tr>
                  <td>
                    (1) кожну особу, яка перебуває в штаті Каліфорнія не з
                    тимчасовою чи тимчасовою метою, і
                  </td>
                  <td>
                    (1) every individual who is in the State of California for
                    other than a temporary or transitory purpose and
                  </td>
                </tr>
                <tr>
                  <td>
                    (2) кожну особу, яка проживає в штаті Каліфорнія, яка
                    перебуває за межами штату Каліфорнія з тимчасовою або
                    тимчасовою метою
                  </td>
                  <td>
                    (2) every individual who is domiciled in the State of
                    California who is outside the State of California for a
                    temporary or transitory purpose
                  </td>
                </tr>
                <tr>
                  <td>Усі інші особи визначаються як «нерезиденти».</td>
                  <td>All other individuals are defined as "non-residents."</td>
                </tr>
                <tr>
                  <td>
                    Якщо це визначення «резидента» стосується вас, ми повинні
                    дотримуватися певних прав і зобов’язань щодо вашої особистої
                    інформації.
                  </td>
                  <td>
                    If this definition of "resident" applies to you, we must
                    adhere to certain rights and obligations regarding your
                    personal information.
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми можемо збирати особисту інформацію, коли ви спілкуєтеся з
                    нами особисто, онлайн, або телефоном чи електронною поштою в
                    контексті:
                  </td>
                  <td>
                    We may also collect other personal information outside of
                    these categories through instances where you interact with
                    us in person, online, or by phone or mail in the context of:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        отримання допомоги через наші канали підтримки клієнтів;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Receiving help through our customer support channels;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Участь в опитуваннях або конкурсах клієнтів; та</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Participation in customer surveys or contests; and
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        сприяння наданню наших Послуг і відповіді на ваші
                        запити.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        Facilitation in the delivery of our Services and to
                        respond to your inquiries.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Як ми використовуємо та ділимося вашою особистою
                      інформацією?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      How do we use and share your personal information?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми використовуємо та надаємо вашу особисту інформацію за
                    допомогою:
                  </td>
                  <td>We use and shares your personal information through:</td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Цільових файлів cookie/Маркетингових файлів cookie
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Targeting cookies/Marketing cookies</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>Маяків/Пікселів/Тегів</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Beacons/Pixels/Tags</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Додаткову інформацію про наші способи збору та обміну даними
                    можна знайти в цієї політики конфіденційності.
                  </td>
                  <td>
                    More information about our data collection and sharing
                    practices can be found in this privacy policy.
                  </td>
                </tr>
                <tr>
                  <td>
                    Ви можете зв’язатися з нами електронною поштою за адресою
                    admin@heyboss.com.ua
                  </td>
                  <td>You may contact us by email at admin@heyboss.com.ua</td>
                </tr>
                <tr>
                  <td>
                    Якщо ви використовуєте уповноваженого агента, щоб
                    скористатися своїм правом відмовитися, ми можемо відхилити
                    запит, якщо уповноважений агент не надасть доказ того, що
                    він був належним чином уповноважений діяти від вашого імені.
                  </td>
                  <td>
                    If you are using an authorized agent to exercise your right
                    to opt out we may deny a request if the authorized agent
                    does not submit proof that they have been validly authorized
                    to act on your behalf.
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми можемо розкривати вашу особисту інформацію нашим
                    постачальникам послуг відповідно до письмового договору між
                    нами та кожним постачальником послуг. Кожен постачальник
                    послуг є комерційною організацією, яка обробляє інформацію
                    від нашого імені.
                  </td>
                  <td>
                    We may disclose your personal information with our service
                    providers pursuant to a written contract between us and each
                    service provider. Each service provider is a for-profit
                    entity that processes the information on our behalf.
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми можемо використовувати вашу особисту інформацію для
                    власних бізнес-цілей, наприклад для проведення внутрішніх
                    досліджень для технологічного розвитку та демонстрації. Це
                    не вважається «продажем» вашої особистої інформації.
                  </td>
                  <td>
                    We may use your personal information for our own business
                    purposes, such as for undertaking internal research for
                    technological development and demonstration. This is not
                    considered to be "selling" of your personal information.
                  </td>
                </tr>
                <tr>
                  <td>
                    Протягом останніх дванадцяти (12) місяців ми не продавали
                    жодної особистої інформації третім сторонам з діловою чи
                    комерційною метою.
                  </td>
                  <td>
                    We have not sold any personal information to third parties
                    for a business or commercial purpose in the preceding twelve
                    (12) months.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Ваші права щодо ваших особистих даних</strong>
                  </td>
                  <td>
                    <strong>
                      Your rights with respect to your personal data
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Право вимагати видалення даних — Запит на видалення
                    </span>
                    <p>
                      Ви можете вимагати видалення вашої особистої інформації.
                      Якщо ви попросите нас видалити вашу особисту інформацію,
                      ми задовольнимо ваш запит і видалимо вашу особисту
                      інформацію, якщо інше не вимагається законом.
                    </p>
                  </td>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Right to request deletion of the data — Request to delete
                    </span>
                    <p>
                      You can ask for the deletion of your personal information.
                      If you ask us to delete your personal information, we will
                      respect your request and delete your personal information,
                      subject to certain exceptions provided by law.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Право на інформацію — Запит на інформацію
                    </span>
                  </td>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Right to be informed — Request to know
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Залежно від обставин ви маєте право знати:</td>
                  <td>
                    Depending on the circumstances, you have a right to know:
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        чи збираємо ми та використовуємо вашу особисту
                        інформацію;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        whether we collect and use your personal information;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>категорії особистої інформації, яку ми збираємо;</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        the categories of personal information that we collect;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        цілі, для яких використовується зібрана персональна
                        інформація;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        the purposes for which the collected personal
                        information is used;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        чи продаємо ми вашу особисту інформацію третім сторонам;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        whether we sell your personal information to third
                        parties;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        категорії особистої інформації, яку ми продали або
                        розкрили з комерційною метою;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        the categories of personal information that we sold or
                        disclosed for a business purpose;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        категорії третіх сторін, яким особиста інформація була
                        продана або розкрита з комерційною метою;
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        the categories of third parties to whom the personal
                        information was sold or disclosed for a business
                        purpose;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        також ділова або комерційна мета збору або продажу
                        особистої інформації.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        the business or commercial purpose for collecting or
                        selling personal information
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Відповідно до чинного законодавства ми не зобов’язані
                    надавати чи видаляти інформацію про споживача, яка була
                    деідентифікована у відповідь на запит споживача, або
                    повторно ідентифікувати індивідуальні дані для перевірки
                    запиту споживача.
                  </td>
                  <td>
                    In accordance with applicable law, we are not obligated to
                    provide or delete consumer information that is de-identified
                    in response to a consumer request or to re-identify
                    individual data to verify a consumer request.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Право на недискримінацію щодо реалізації прав споживача на
                      конфіденційність
                    </span>
                  </td>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Right to Non-Discrimination for the Exercise of a
                      Consumer’s Privacy Rights
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми не будемо дискримінувати вас, якщо ви використовуєте свої
                    права на конфіденційність.
                  </td>
                  <td>
                    We will not discriminate against you if you exercise your
                    privacy rights.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Процес перевірки
                    </span>
                  </td>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Verification process
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Отримавши ваш запит, нам потрібно буде підтвердити вашу
                    особу, щоб визначити, що ви та сама особа, про яку ми маємо
                    інформацію в нашій системі. Така перевірка вимагає наш
                    запрос до вас надати інформацію, щоб ми могли порівняти її з
                    інформацією, яку ви нам надали раніше. Наприклад, залежно
                    від типу запиту, який ви подаєте, ми можемо попросити вас
                    надати певну інформацію, щоб ми могли порівняти надану вами
                    інформацію з інформацією, яку ми вже маємо, або ми можемо
                    зв’язатися з вами за допомогою методу зв’язку (наприклад,
                    телефону або електронної пошти), які ви надали нам раніше.
                    Ми також можемо використовувати інші методи перевірки
                    залежно від обставин.
                  </td>
                  <td>
                    Upon receiving your request, we will need to verify your
                    identity to determine you are the same person about whom we
                    have the information in our system. These verification
                    efforts require us to ask you to provide information so that
                    we can match it with information you have previously
                    provided us. For instance, depending on the type of request
                    you submit, we may ask you to provide certain information so
                    that we can match the information you provide with the
                    information we already have on file, or we may contact you
                    through a communication method (e.g., phone or email) that
                    you have previously provided to us. We may also use other
                    verification methods as the circumstances dictate.
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми використовуватимемо особисту інформацію, надану у вашому
                    запиті, лише для підтвердження вашої особи чи повноважень
                    для подання запиту. Наскільки це можливо, ми будемо уникати
                    запросу від вас додаткової інформації з метою перевірки.
                    Проте, якщо ми не можемо підтвердити вашу особу на основі
                    інформації, яку ми вже зберігаємо, ми можемо вимагати від
                    вас надати додаткову інформацію з метою перевірки вашої
                    особи та з метою безпеки чи запобігання шахрайству. Ми
                    видалимо таку додатково надану інформацію, щойно завершимо
                    вашу перевірку.
                  </td>
                  <td>
                    We will only use personal information provided in your
                    request to verify your identity or authority to make the
                    request. To the extent possible, we will avoid requesting
                    additional information from you for the purposes of
                    verification. However, if we cannot verify your identity
                    from the information already maintained by us, we may
                    request that you provide additional information for the
                    purposes of verifying your identity and for security or
                    fraud-prevention purposes. We will delete such additionally
                    provided information as soon as we finish verifying you.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Інші права на конфіденційність
                    </span>
                  </td>
                  <td>
                    <span style={{ textDecoration: 'underline' }}>
                      Other privacy rights
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Ви можете заперечувати проти обробки вашої особистої
                        інформації.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        You may object to the processing of your personal
                        information.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Ви можете вимагати виправлення своїх персональних даних,
                        якщо вони невірні або більше не актуальні, або просити
                        обмежити обробку інформації.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        You may request correction of your personal data if it
                        is incorrect or no longer relevant, or ask to restrict
                        the processing of the information.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Ви можете призначити уповноваженого агента для подання
                        запиту відповідно до CCPA від вашого імені. Ми можемо
                        відхилити запит від уповноваженого агента, який не надав
                        доказів того, що він був належним чином уповноважений
                        діяти від вашого імені відповідно до CCPA.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        You can designate an authorized agent to make a request
                        under the CCPA on your behalf. We may deny a request
                        from an authorized agent that does not submit proof that
                        they have been validly authorized to act on your behalf
                        in accordance with the CCPA.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        Ви можете попросити відмовитися від майбутнього продажу
                        вашої особистої інформації третім особам. Отримавши
                        запит на відмову, ми задовольнимо запит якнайшвидше, але
                        не пізніше ніж через п’ятнадцять (15) днів із дати
                        подання запиту.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        You may request to opt out from future selling of your
                        personal information to third parties. Upon receiving an
                        opt-out request, we will act upon the request as soon as
                        feasibly possible, but no later than fifteen (15) days
                        from the date of the request submission.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Щоб скористатися цими правами, ви можете зв’язатися з нами
                    електронною поштою за адресою admin@heyboss.com.ua.
                  </td>
                  <td>
                    To exercise these rights, you can contact us by email at
                    admin@heyboss.com.ua.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      12. ЧИ МИ РОБИМО ОНОВЛЕННЯ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <em>
                        Коротко: так, ми будемо оновлювати цю політику
                        конфіденційності, якщо це необхідно, щоб дотримуватися
                        відповідних законів.
                      </em>
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <em>
                        In Short: Yes, we will update this notice as necessary
                        to stay compliant with relevant laws.
                      </em>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ми можемо час від часу оновлювати цю політику
                    конфіденційності. Оновлена ​​версія буде позначена оновленою
                    датою «Переглянута», і оновлена ​​версія набуде чинності,
                    щойно стане доступною. Якщо ми внесемо суттєві зміни в цю
                    політику конфіденційності, ми можемо повідомити вас,
                    розмістивши повідомлення про такі зміни на помітному місці,
                    або безпосередньо надіславши вам повідомлення. Радимо вам
                    частіше переглядати цю політику конфіденційності, щоб бути в
                    курсі того, як ми захищаємо вашу інформацію.
                  </td>
                  <td>
                    We may update this privacy policy from time to time. The
                    updated version will be indicated by an updated "Revised"
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    policy, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy policy
                    frequently to be informed of how we are protecting your
                    information.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      13. ЯК ВИ МОЖЕТЕ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ
                      КОНФІДЕНЦІЙНОСТІ?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      13. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Якщо у вас є запитання чи коментарі щодо цієї політики
                    конфіденційності, ви можете надіслати нам електронний лист
                    на адресу admin@heyboss.com.ua або поштою на адресу:
                    <p>
                      ГРОМАДСЬКА ОРГАНІЗАЦІЯ «ГЕЙ БОСС», ідентифікаційний код
                      юридичної особи 44642517, Україна, 65023, Одеська обл.,
                      місто Одеса, вул. Ніжинська, будинок 34, квартира 6
                    </p>
                  </td>
                  <td>
                    If you have questions or comments about this notice, you may
                    email us at admin@heyboss.com.ua or by post to:
                    <p>
                      Non governmental organization «Hey Boss», Identification
                      code of the legal entity 44642517; 65023, Odessa, Odessa
                      region, Nizhyns'ka Street 34 apartment 6
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      14. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ,
                      ЯКІ МИ ЗБИРАЄМО ВІД ВАС?
                    </strong>
                  </td>
                  <td>
                    <strong>
                      14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Ви маєте право вимагати доступу до особистої інформації, яку
                    ми збираємо від вас, змінити цю інформацію або видалити її.
                    Щоб подати запит на перегляд, оновлення або видалення вашої
                    особистої інформації, ви можете надіслати нам електронний
                    лист на адресу admin@heyboss.com.ua
                  </td>
                  <td>
                    You have the right to request access to the personal
                    information we collect from you, change that information, or
                    delete it. To request to review, update, or delete your
                    personal information, you may email us at
                    admin@heyboss.com.ua
                  </td>
                </tr>
                <tr>
                  <td>
                    Ця політика конфіденційності складена українською та
                    англійською мовами. У разі виникнення суперечок в її
                    тлумаченні, пов’язаних з перекладом, завжди застосовується
                    українська версія.
                  </td>
                  <td>
                    This Privacy Policy is made in Ukrainian and English. In
                    case of disputes in the interpretation of it related to the
                    translation, the Ukrainian version is always used.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class='s-100'></div>
        <div class='m-40'></div>
      </section>
    </>
  );
};

export { Privacy };
